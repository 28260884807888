// Core variables and mixins from Bootstrap
@import "../../custom";

#location-share {
	display: none;
}

#home-page-login {

	@include media-breakpoint-up(md) {
		padding: 10px 0;
	}

	.log-in-buttons {
		display: inline-block;

		.remember-me{
			padding-left: 15px;

			@include media-breakpoint-down(md) {
				padding-left: 0;
			}
		}
	}
}

/* BANNER */
#full-banner {
	background-color: #0e3563;
	color: #FFF;
}

.capabilities {
	content: "";
	background: url('/static/img/institutional/homepage/dot_map.png') no-repeat right center;
	background-size: 50%;
	z-index: 0;

	@include media-breakpoint-down(sm) {
		background-size: 155%;
		background: linear-gradient(to bottom, rgba(14,53,99,1) 25%, rgba(14,53,99,0) 100%), url(/static/img/institutional/homepage/dot_map.png) no-repeat;
		background-position: bottom;

		h2 {
			margin: 0.6em 0;
			line-height: 29px;
			font-size: 19px;
		}
	}
}

.backdrop {
	background-color: rgba(0,0,0,0.3);
	-webkit-box-shadow: 0 0 7px 0 rgba(0,0,0,0.1);
	box-shadow: 0 0 7px 0 rgba(0,0,0,0.1);
	color: #FFF;
	padding: 20px 0;
	margin-left: 60px;

	@include media-breakpoint-down(md) {
		margin-left: 0px;
	}
}

.fund-class-selector {

	@include media-breakpoint-down(md) {
		padding: 0 !important;
	}

	a {
		display: block;
		margin-bottom: 5px;
		text-decoration: none;
		background-color: #fff;
		position: relative;
		padding: 20px 0px 20px 20px;
		margin: 4px 10px;

		&:hover {
			background-color: #f8f8f8;
			border-color: #bbb;
		}
	}

	.no-fund-colors {
		a {
			padding: 12px 10px 12px 10px;
		}
	}
}

.fund-color {
	width: 10px;
	position: absolute;
	height: 100%;
	top: 0;
	left: 0;

	&.equity {
		background-color: #1069c9;
	}

	&.fixed-income {
		background-color: #fd9827;
	}

	&.retail-fund {
		background-color: #ddba17;
	}

	&.risk-allocation {
		background-color: #983897;
	}
	&.sustainable {
		background-color: #60bb46;
	}
	&.alternatives {
		background-color: #778e9d;
	}
}

.navy-border {
	border-bottom: 1px solid #0e3563;
}

.region-contact{
	ul {
		list-style: none;
		padding: 0;
	}

	li {
		display: inline-block;
	}

	@include media-breakpoint-down(sm) {
		
		li {
			display: list-item;
		}

		ul {
			list-style: disc;
			padding: 0 0 0 20px;
		}
	}
}

.post-featured{
	position: relative;
	overflow: hidden;
}
.contact-block {
	padding: 30px 0;

	@include media-breakpoint-down(xs) {
		padding: 0;
	}

	.contact-map {

		@include media-breakpoint-up(md) {
			margin-top: -100px;
		}
	}
}
.share-bar {
	position: absolute;
	margin: 0;
	padding: 3px 4px 3px 3px;
	width: 100%;
	background-color: rgba(255,255,255,0.7);
	filter:progid:DXImageTransform.Microsoft.gradient(startColorstr=#B3f58220,endColorstr=#B3f58220);
	-webkit-transition: all 0.35s;
	transition: all 0.35s; 
	top: -40px;

	@at-root .effect-share-reveal:hover #{&} {
		top: -1px;
	}
}

.gray-band {
	background-color: #f6f7fa;
}

.white-bg {
	margin-bottom: 2em;

	.inset {
		background-color: white;
		border: 1px solid #CCC;
		padding: 15px;

		&.full-height {
			height: 100%;
		}
	}
}

@include media-breakpoint-down(md) {
	div[class*="-equal"] > div {
		height: auto !important;
	}
}

.current-views-blocks {
	background-color: #FFF;
	background-color: rgba(255,255,255,0.8);
	padding: 15px;
	margin-bottom: 15px;
	border: 1px solid #ccc;

	hr {
		border-color: #333;
	}
}
